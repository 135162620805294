<template>
  <v-card>
    <v-card-title class="d-flex justify-center">Відновлення паролю</v-card-title>
    <v-card-text>
      <v-form
        class="pa-2"
        ref="forgotPasswordForm"
        v-model="validForm"
        lazy-validation
        @submit.prevent="handleSubmit"
      >
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              :loading="loading"
              dense
              outlined
              v-model="email"
              ref="email"
              :rules="rules"
              type="email"
              label="E-mail"
              required
            ></v-text-field>
          </v-col>
          <v-col class="d-flex justify-center pa-0" cols="12">
            <v-btn
              type="submit"
              :loading="loading"
              :disabled="!validForm"
              color="success"
            >Відправити інструкцію
            </v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ForgotPasswordForm',
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      email: '',
      rules: [
        (v) => !!v || "Обов'язковe поле",
        (v) => /.+@.+\..+/.test(v) || 'E-mail має бути дійсним'
      ],
      validForm: false
    }
  },
  mounted () {
    this.focusInput('email')
  },
  methods: {
    handleSubmit () {
      this.$refs.forgotPasswordForm.validate() &&
      this.$emit('onSubmit', this.email)
    },
    focusInput (name) {
      this.$refs[name].focus()
    }
  }
}
</script>
